.super-admin-menu-item-icon {
  @apply w-7 h-7 object-scale-down fill-current text-qr8-green;
}

.super-admin-menu-item {
  @apply text-base truncate transition duration-150 hover:bg-qr8-gray-highlight pl-8 pt-4 pb-3;
}

.super-admin-link {
  @apply font-medium text-gray-900 transition duration-150 hover:bg-qr8-gray-highlight ease-in-out;
}

.super-admin-content {
  @apply flex;
}

.super-admin-p {
  @apply text-qr8-green font-bold ml-5 text-lg;
}

.super-admin-p-description {
  @apply text-base text-gray-500 font-normal ml-2;
}
