.detail-item-textfield {
  @apply max-w-lg block w-full shadow-sm focus:ring-qr8-green focus:border-qr8-green sm:max-w-xs sm:text-base border-gray-300 rounded-md;
}

.detail-item-textfield-error {
  @apply max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-base rounded-md border-red-300 text-red-900 placeholder-red-300 focus:ring-red-300 focus:border-red-300;
}

.detail-item-textfield-grid {
  @apply sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5;
}

.detail-item-label {
  @apply block text-base font-medium text-gray-700 sm:pt-2 -mt-1;
}

.detail-item-input {
  @apply mt-1 sm:mt-0 sm:col-span-2;
}

.detail-item-error {
  @apply inset-y-0 right-0 pr-3 flex items-center pointer-events-none -mt-7 ml-72;
}

.detail-item-icon {
  @apply h-5 w-5 text-red-500;
}

.detail-item-error-container {
  @apply mt-3;
}

.detail-item-error-text {
  @apply text-red-500 mt-3 text-base;
}

.detail-item-value {
  @apply mt-1 !important;
}

.detail-item-input li {
  @apply text-gray-700 !important;
  margin-left: 15px;
  list-style-type: circle;
}

.detail-item-link a {
  @apply text-qr8-green;
}

li::marker {
  @apply text-qr8-green;
}
