.module-icons {
  @apply flex space-x-1;
}

.small-module-icons {
  width: 35px;
  height: 35px;
}

.small-module-icons-canceled {
  width: 35px;
  height: 35px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  opacity: 0.33;
}
