.login-wrapper {
  @apply min-h-screen bg-white flex;
}

.login-inner-wrapper {
  @apply flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24;
}

.login-left {
  @apply mx-auto w-full max-w-sm lg:w-96 mb-10;
}

.login-right {
  @apply hidden lg:block relative w-0 flex-1;
}

.login-image {
  @apply h-12 w-auto -ml-2;
}

.login-slogan {
  @apply mt-4 font-medium text-gray-500 text-base;
}

.login-label {
  @apply block text-sm font-medium text-gray-700;
}

.login-label-error {
  @apply block text-sm font-medium text-red-500;
}

.login-textfield {
  @apply appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-qr8-green focus:border-qr8-green sm:text-sm disabled:opacity-50;
}

.login-textfield-error {
  @apply appearance-none block w-full px-3 py-2 border border-red-500 rounded-md shadow-sm placeholder-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm;
}

.login-button {
  @apply w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-qr8-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-qr8-green hover:bg-qr8-light-green;
  width: 100% !important;
  max-width: 40rem !important;
}

.login-recovery {
  @apply font-medium text-qr8-green hover:text-qr8-light-green;
}

.login-error {
  @apply text-red-500 mt-2;
}

.login-icon {
  @apply text-red-500 -mt-10;
  margin-left: 200px !important;
  height: 5px !important;
  width: 5px !important;
}
