.patient-page-header {
  @apply mt-10 mb-14;
}

.patient-page-header-subtitle {
  @apply text-qr8-green font-bold;
}

.patient-page-header-title {
  @apply text-5xl font-extrabold text-qr8-black mt-1;
}

.patient-page-header-buttons {
  @apply flex mt-2;
  flex-flow: row wrap;
}

.patient-page-header-buttons p {
  @apply mr-5 text-qr8-light-gray;
}

.patient-page-header-buttons span {
  @apply font-medium text-qr8-darker-gray;
}

.patient-header-button-tray {
  @apply mt-5 space-x-2;
}
