.assessment-list-container {
  @apply mt-6;
  margin-right: 0;
  padding-right: 0;
}

.assessment-list-button-tray {
  display: flex;
  width: 304px;
  margin-left: auto;
  margin-right: 0;
}

.assessment-list {
  @apply mt-6 mb-7;
}

.assessment-row-upper {
  @apply text-sm font-semibold text-gray-900;
}

.assessment-row-lower {
  @apply text-sm text-gray-500 font-normal;
}

.assessment-row-data {
  @apply px-4 py-4 whitespace-nowrap text-sm text-gray-500;
}
