.footer-container {
  @apply max-w-4xl mx-auto py-10 px-4 overflow-hidden text-center;
}

.footer-text {
  @apply mt-8 text-center text-base text-gray-400;
}

.footer-text a {
  @apply text-gray-400;
}
