.search {
  @apply flex mt-10 mb-4 space-x-2;
}

.search-box {
  @apply w-96 mr-3;
}

.search-wrapper {
  @apply flex-1 flex justify-start;
}

.search-inner-wrapper {
  @apply max-w-sm w-full;
}

.search-input-wrapper {
  @apply relative text-gray-500 focus-within:text-gray-600 h-10;
}

.search-input {
  @apply text-base block w-full placeholder-gray-500 bg-white py-2 pl-4 pr-3 border border-gray-200 rounded-md leading-5 text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-qr8-green focus:ring-white focus:border-qr8-green h-10;
}

.search-field-selection {
  @apply text-base text-gray-500 block shadow-sm focus:ring-qr8-green focus:border-qr8-green sm:max-w-xs sm:text-base rounded-md border-gray-200;
}

.search-button {
  height: 38px;
}
