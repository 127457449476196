.menu-container {
  @apply max-w-4xl mx-auto;
}

.menu-inner-border {
  @apply max-w-md float-right mt-1;
  position: absolute;
  z-index: 1;
}

.menu-item-container {
  @apply rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden;
}

.menu-inner-container {
  @apply relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8;
}
