.error-alert {
  @apply bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-10 -mt-5 max-w-full;
}

.error-alert a {
  @apply text-red-700 !important;
}

.error-alert-message {
  @apply text-red-500 !important;
}
