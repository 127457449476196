.qr8-button {
  @apply mr-1 h-9 inline-flex items-center px-6 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-qr8-green transition duration-150 hover:bg-qr8-light-green focus:outline-none focus:ring-2 focus:ring-offset-2 max-w-xs disabled:opacity-50;
}

.qr8-button-icon {
  @apply h-5 w-5 -ml-1 mr-2;
}

.qr8-button-secondary {
  @apply mr-1 h-9 inline-flex items-center px-5 py-2 border border-qr8-green shadow-sm text-sm font-medium rounded-md text-qr8-green bg-white transition duration-150 hover:bg-qr8-extra-light-green focus:outline-none focus:ring-2 focus:ring-offset-2 max-w-xs disabled:opacity-50;
}

.qr8-small-button {
  @apply -mr-1;
}
