.navigation-menu-item {
  @apply text-gray-500 group bg-white rounded-md inline-flex items-center text-base font-medium transition duration-150 hover:text-gray-900 focus:outline-none text-right;
}

span {
  @apply text-right;
}

.navigation-chevron {
  @apply text-gray-300 ml-1 h-4 w-4 transition duration-150 group-hover:text-gray-800;
}
