.date-picker {
  @apply m-1 justify-center;
  display: 'flex';
  width: 300px;
  height: 60px;
}

.date-picker-button {
  @apply ml-2 mr-2;
}

.date-picker-selection {
  @apply ml-1 border focus:ring-2 focus:ring-offset-2 border-gray-200 rounded-md focus:ring-offset-qr8-green focus:ring-white focus:border-qr8-green;
}

.react-datepicker__header.react-datepicker__header--custom {
  background-color: #fbfbf9;
}

.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
  background-color: #00626e;
}

.react-datepicker__day--selected {
  background: #00626e !important;
}
