.page-header {
  @apply mt-12 mb-14;
}

.page-header-subtitle {
  @apply text-qr8-green font-bold;
}

.page-header-title {
  @apply text-5xl font-extrabold text-qr8-black mt-1;
}

.page-header-description {
  @apply text-qr8-dark-gray mt-1;
}
