.latest-score {
  margin-left: 8px;
  margin-right: 8px;
}

.latest-score-description {
  @apply mb-8 mr-2;
  margin-top: 15px;
}

.latest-score-description h1 {
  @apply text-qr8-light-green font-bold;
}

.score-values {
  @apply flex space-x-8;
}

.score-values h1 {
  @apply text-qr8-light-gray;
  width: 60px;
  text-align: center;
}

.score h2 {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}

.no-scores {
  @apply p-3 text-center text-qr8-light-gray m-auto;
}

.z-score h1 {
  margin-left: 20px;
}
