.module-graph {
}

.graph {
  margin-top: 10px;
  margin-left: -60px;
  overflow: visible;
}

.graph-right h1 {
  @apply text-qr8-light-green font-bold;
  text-align: center;
  margin-left: -20px;
  margin-top: 15px;
}

.graph-left p {
  @apply transform -rotate-90 text-qr8-light-gray;
  margin-left: -25px;
  margin-top: 150px;
  margin-right: 5px;
}
