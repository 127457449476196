.form-field {
  @apply max-w-lg w-full block shadow-sm focus:ring-qr8-green focus:border-qr8-green sm:max-w-xs sm:text-base border-gray-300 rounded-md;
}

.react-datepicker-wrapper {
  @apply w-full;
}

.form-textfield-error {
  @apply max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-base rounded-md border-red-300 text-red-900 placeholder-red-300 focus:ring-red-300 focus:border-red-300;
}

.form-textfield-grid {
  @apply sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 !important;
}

.form-label {
  @apply block text-base font-medium text-gray-700 sm:mt-px sm:pt-2;
}

.form-input {
  @apply mt-1 sm:mt-0 sm:col-span-2;
}

.form-textfield-error {
  @apply inset-y-0 right-0 pr-3 flex items-center;
}

.form-icon {
  @apply h-5 w-5 text-red-500 -mt-10 ml-72;
}

.form-error-container {
  @apply mt-3;
}

.form-error-text {
  @apply text-red-500 mt-3 text-base;
}

.form-checkbox {
  @apply focus:ring-qr8-green h-4 w-4 text-qr8-green border-gray-300 rounded mt-2 disabled:opacity-50;
}
