.menu-link {
  @apply -m-3 p-3 flex items-start rounded-lg transition duration-150 hover:bg-qr8-green-highlight;
}

.menu-image {
  @apply flex-none w-7 h-7 object-scale-down fill-current text-qr8-green;
}

.menu-wrapper {
  @apply flex;
}

.menu-item {
  @apply ml-4;
}

.menu-item-title {
  @apply -mt-1 font-bold text-qr8-green text-lg text-left;
}

.menu-item-description {
  @apply -mt-1 text-base text-gray-500;
  text-align: left;
}
