.navigation {
  @apply relative bg-white;
}

.navigation-inner {
  @apply mx-auto;
  width: 896px;
}

.navigation-items {
  @apply flex justify-between items-end border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10;
}

.navigation-item-logo {
  @apply flex justify-start;
}

.navigation-items-right {
  @apply md:flex items-center justify-end md:flex-1 space-x-6;
}
