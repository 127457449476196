.module-overview {
  width: 425px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  white-space: normal;
}

.module-overview .module-description img {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 0px;
  width: 40px;
  height: 40px;
}

.module-overview .module-description h1 {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.module-overview .module-description p {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}

.module-overview .score-description {
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 10px;
  font-size: 14px;
}

.module-overview .score-values {
  margin-left: 15px;
  margin-right: auto;
}

.module-overview .score-values h1 {
  @apply text-qr8-light-gray;
  width: 60px;
  text-align: center;
  font-size: 14px;
}

.module-overview .score h2 {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.module-overview .z-score h1 {
  margin-left: 15px;
}

.module-inner-section {
  margin: 0;
  padding: 10px;

  border-width: 1px;
  border-color: #f5f5f0;
  display: flex;
}

.module-inner-section:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left-width: 0px;
  width: 250px;
}

.module-overview-row {
  background-color: #f5f5f0;
  width: 220px;
}

.module-center-description {
  @apply p-3 text-center text-qr8-light-gray m-auto;
}
