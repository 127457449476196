.form {
  @apply mt-14;
}

.form-outer {
  @apply space-y-8 divide-y divide-gray-200;
}

.form-inner {
  @apply space-y-8 divide-y divide-gray-200;
}

.form-header {
  @apply pt-8 space-y-6 sm:pt-10 sm:space-y-5;
}

.form-header h3 {
  @apply text-2xl leading-6 font-medium text-gray-900;
}

.form-header p {
  @apply mt-1 max-w-2xl text-base text-gray-500;
}

.form-children {
  @apply space-y-5;
}

.form-tray {
  @apply pt-5 flex;
}

.form-error {
  @apply text-red-500 mr-auto text-left ml-0 pr-4;
  margin-top: 0px !important;
  margin-left: 0 !important;
}

.form-buttons {
  @apply flex justify-end;
}
