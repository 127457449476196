.modules-overview {
  width: 100%;
  height: 350px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  overflow: hidden;
}

.modules-inner-section {
  width: 33%;
  margin: 0;
  padding: 10px;

  border-width: 1px;
  border-color: #f5f5f0;
  display: flex;
}

.modules-graph {
  width: 360px;
}

.modules-inner-section:last-child {
  width: 250px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left-width: 0px;
}

.modules-overview-row {
  background-color: #f5f5f0;
}
