.super-admin-menu-container {
  @apply max-w-4xl mx-auto;
}

.super-admin-menu-inner-border {
  @apply mt-3 max-w-4xl float-right;
  position: absolute;
  z-index: 1;
  margin-left: 250px;
}

.super-admin-menu-item-container {
  @apply rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden;
}

.super-admin-menu-inner-container {
  @apply grid grid-cols-2 gap-2 bg-white px-5 py-6 sm:gap-8 sm:p-8;
}

.super-admin-menu-lower-tray {
  @apply py-5 pb-5 bg-qr8-light-yellow;
}
