.flag-alert {
  @apply mt-14;
}

.red-flag-alert {
  @apply bg-red-100 border-red-500 border-2 rounded-lg text-red-700 p-4 mb-10 -mt-5 max-w-full;
}

.flag-alert-inner {
  @apply flex space-x-3;
}

.red-flag-alert a {
  @apply text-red-700 !important;
}

.red-flag-alert-message {
  @apply font-bold text-red-600 !important;
}

.yellow-flag-alert {
  @apply bg-yellow-100 border-yellow-500 border-2 rounded-lg text-red-700 p-4 mb-10 -mt-5 max-w-full;
}

.flag-alert-icon {
  @apply mt-1 w-6 h-6;
}

.yellow-flag-alert a {
  @apply text-yellow-700 !important;
}

.yellow-flag-alert-message {
  @apply font-bold text-yellow-600 !important;
}
