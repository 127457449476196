.user-menu-container {
  @apply mx-auto;
  width: 460px;
}

.user-menu-inner-border {
  width: 460px;
  position: absolute;
  z-index: 1;
}
