.dialog {
  @apply fixed z-10 inset-0 overflow-y-auto;
}

.dialog-outer {
  @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
}

.dialog-overlay {
  @apply fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
}

.dialog-overlay-trick {
  @apply hidden sm:inline-block sm:align-middle sm:h-screen;
}

.dialog-container {
  @apply inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6;
}

.dialog-items {
  @apply sm:flex sm:items-start;
}

.dialog-middle-area {
  @apply mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10;
}

.dialog-icon {
  @apply h-6 w-6 text-red-600;
}

.dialog-middle-area-warning {
  @apply mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10;
}

.dialog-icon-warning {
  @apply h-6 w-6 text-yellow-600;
}

.dialog-title {
  @apply mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left;
}

.dialog-button-tray {
  @apply mt-5 sm:mt-4 sm:flex sm:flex-row-reverse;
}

.dialog-destructive-button {
  @apply w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm;
}

.dialog-cancel-button {
  @apply mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:mt-0 sm:w-auto sm:text-sm;
}
