.tabview {
  @apply flex space-x-12 h-8;
  border-color: #e9e9e8;
  border-bottom-width: 1px;
}

.tab-icon {
  @apply w-5 h-5 text-qr8-light-gray;
}

.tab-text {
  @apply -mt-0.5 text-qr8-light-gray;
}

.tab-icon-selected {
  @apply w-5 h-5 text-qr8-green font-bold;
}

.tab-text-selected {
  @apply -mt-0.5 text-qr8-green font-bold;
}

.tabview-button-inner {
  @apply flex space-x-2;
}

.tabview-button-inner-selected {
  @apply flex space-x-2 border-b-2;
  border-collapse: separate;
  border-spacing: 40px;
  padding-bottom: 9px;
  border-color: #00616e;
}
