.module-description {
  @apply m-auto;
}

.module-description img {
  margin-bottom: 15px;
  margin-left: 25px;
  width: 60px;
  height: 60px;
}

.module-description h1 {
  @apply m-auto font-bold text-qr8-light-green;
  margin-left: 25px;
  margin-right: 20px;
  font-size: 18px;
}

.module-description p {
  @apply m-auto text-qr8-black;
  margin-left: 25px;
  margin-right: 20px;
  margin-bottom: 20px;
}
