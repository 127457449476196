.app-container {
  @apply mx-auto;
  width: 896px;
}

a {
  color: #00616e;
}

a:hover {
  color: #1b8599;
}

::selection {
  background: #bbf1f4;
}

::-moz-selection {
  background: #bbf1f4;
}
