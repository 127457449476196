.organization-row-upper {
  @apply text-sm font-semibold text-gray-900;
}

.organization-row-lower {
  @apply text-sm text-gray-500 font-normal;
}

.organization-row-name {
  @apply px-4 py-4 whitespace-nowrap font-medium;
}

.organization-row-data {
  @apply px-4 py-4 whitespace-nowrap text-sm text-gray-500;
}
