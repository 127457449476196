.table {
  @apply flex flex-col mb-10;
}

.table-inner {
  @apply -my-2 overflow-x-visible -mx-6;
}

.table-wrapper {
  @apply py-2 align-middle inline-block min-w-full px-6;
}

.table-wrapper-shadow {
  @apply shadow overflow-hidden border-b border-qr8-light-yellow sm:rounded-lg;
}

.table table {
  @apply min-w-full divide-y divide-gray-200;
}

.table thead {
  @apply bg-qr8-light-yellow;
}

.table th {
  @apply px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}

tbody {
  @apply bg-white divide-y divide-gray-200;
}

td {
  @apply px-4 py-4 whitespace-nowrap;
}

.table-th-buttons {
  @apply px-4 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider !important;
}

.sort-icon {
  @apply w-4 h-4 ml-2 fill-current text-gray-400;
}
