.detail-view {
  @apply mt-14;
}

.detail-view-outer {
  @apply space-y-8 divide-y divide-gray-200;
}

.detail-view-inner {
  @apply space-y-8 divide-y divide-gray-200;
}

.detail-view-header {
  @apply pt-8 space-y-6 sm:pt-10 sm:space-y-5;
}

.detail-view-header h3 {
  @apply text-2xl leading-6 font-medium text-gray-900;
}

.detail-view-header p {
  @apply mt-1 max-w-2xl text-base text-gray-500;
}

.detail-view-children {
  @apply space-y-5;
}

.detail-view-tray {
  @apply pt-5;
}

.detail-view-error {
  @apply float-left text-red-500;
}

.detail-view-buttons {
  @apply flex justify-start;
}
