.no-data-page-header {
  @apply mt-10 mb-14 text-center;
}

.no-data-page-header-subtitle {
  @apply text-qr8-green font-bold text-center;
}

.no-data-page-header-title {
  @apply text-3xl font-extrabold text-qr8-black mt-1;
}

.no-data-page-header-description {
  @apply text-qr8-dark-gray mt-1 text-center m-auto mb-5;
  max-width: 400px;
}

.no-data-page-header-img {
  @apply w-80 m-auto mt-10;
}
