.patient-downloads {
  @apply mt-6;
}

.patient-downloads-text {
  @apply border-gray-300 rounded-md focus:border-qr8-green focus:ring-qr8-green mt-5 mb-5;
  white-space: pre-line;
}

.copy-to-clipboard {
  @apply mb-3 mt-3;
}
