.pagination-nav {
  @apply border-t border-gray-200 px-4 flex items-center justify-between sm:px-0;
}

.pagination-wrapper {
  @apply -mt-px w-0 flex-1 flex;
}

.pagination-btn {
  @apply focus:outline-none border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300;
}

.pagination-arrow-left {
  @apply mr-3 h-5 w-5 text-gray-400;
}

.pagination-arrow-right {
  @apply ml-3 h-5 w-5 text-gray-400;
}

.pagination-page-number {
  @apply focus:outline-none border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium;
}

.pagination-page-number-current {
  @apply focus:outline-none border-transparent hover:border-qr8-light-green border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium border-qr8-green text-qr8-green;
}

.pagination-between {
  @apply border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium;
}
